"use client";
import Layout from "~/components/layout/Layout";
import Image from "next/image";

export default function MobileScreen() {
  return (
    <Layout>
      <div className="flex-col py-40 text-center justify-center items-center">
        <div className="flex justify-center">
          <Image width="200" height="200" alt="openq-logo" src="/logo.png" />
        </div>

        <div className="flex mt-4 p-8">
          Please use a desktop device to access our DRM application.
        </div>
      </div>
    </Layout>
  );
}
